<template>
<div style="width:100%">
<v-dialog v-model="dialogAnilisis" width="1000">
  <v-card>
    <v-toolbar dark :color="colores.primario">
      <v-btn icon dark @click="cerrar_ventana"><v-icon>mdi-close</v-icon></v-btn>
      <span class="pr-3"></span> ANÁLISIS
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete v-model="objCausal" v-on:input="input_causal(objCausal)" return-object :items="items.items_causaraizreq" item-text="descripcion" item-value="id" label="CAUSA RAÍZ REQUERIMIENTOS *" :color="colores.primario"></v-autocomplete>
          </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete v-model="objMotivo" v-on:input="input_motivo(objMotivo)" return-object :loading="loadingMotivo" :items="items_motivos" item-text="descripcion" item-value="id" label="MOTIVO CAUSA *" :color="colores.primario"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete v-model="objSubmotivo" :items="items_submotivos" return-object item-text="descripcion" item-value="id" label="SUBMOTIVO CAUSA *" :color="colores.primario"></v-autocomplete>
        </v-col>
      </v-row>

      <br>
      <v-row no-gutters>
        <v-col cols="12" md="4" class="pr-1">
          <v-autocomplete v-model="objResponsable" :items="items.areas" return-object item-text="descripcion" item-value="id" label="RESPONSABLE QUEJA"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" class="pr-1">
          <v-autocomplete v-model="objCausalqueja" return-object :items="items.items_causalquejas" item-text="descripcion" item-value="id" label="CAUSA QUEJA"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete v-model="objMarcacion" :items="items.items_marcacionessegui" return-object item-text="descripcion" item-value="id" label="MARCACIÓN SEGUIMIENTO"></v-autocomplete>
        </v-col>
      </v-row>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :color="colores.primario" dark @click="agregar">AGREGAR</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

</div>
</template>

<script>
import recListado from '@/js/rec_listado.js'

export default {
  name: 'form_qr_cierre_dialogoComponent',
  components: {
  },
  mixins: [recListado],
  props: ['dialogAnilisis', 'tokenAnalisis', 'colores', 'rules', 'items'],
  data: () => ({
    loadingMotivo: false,
    loadingSubmotivo: false,
    objCausal: null,
    objMotivo: null,
    objSubmotivo: null,
    objResponsable: null,
    objCausalqueja: null,
    objMarcacion: null,
    items_motivos: [],
    items_submotivos: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  watch: {
    tokenAnalisis: {
      immediate: true,
      handler (newVal, oldVal) {
        this.objCausal = null
        this.objMotivo = null
        this.objSubmotivo = null
        this.objResponsable = null
        this.objCausalqueja = null
        this.objMarcacion = null
      }
    }
  },
  methods: {
    input_causal (objCausal) {
      this.objMotivo = null
      this.objSubmotivo = null
      this.rec_listado_cierre_causal(objCausal.id)
    },
    input_motivo (objMotivo) {
      this.rec_listado_cierre_motivo(objMotivo.id)
    },
    cerrar_ventana () {
      this.$emit('closed_analisis')
    },
    agregar () {
      this.$emit('add_analisis', this.objCausal, this.objMotivo, this.objSubmotivo, this.objResponsable, this.objCausalqueja, this.objMarcacion)
    }
  }
}
</script>
